import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
} from "reactstrap";
import Loader from "../../CommonComponent/Loader";
import * as api from "../../../api/global.api";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const BulkUpload = (props: any) => {
  let id = useParams().id;
  const [file, setFiles] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState([]);

  const handleAcceptedFiles = (files: any, id: any) => {
    setLoading(true);
    let ext = files[0]?.name.split(".");
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      })
    );

    if (ext[1] !== "xlsx") {
      toast.error("Please upload xlsx format file");
      setLoading(false);
    } else {
      setFiles(ext[0]);
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("corridorId", id);
      api
        .post("bulkUploadTower", formData)
        .then((res) => {
          if (res?.status?.toLowerCase() === "success") {
            props.getTowers(1);
            toast.success("File uploaded successfully");
            setLoading(false);
            props.setOpen(false);
          } else {
            console.log(res);
            // toast.error("File has incorrect data, Please check.");
            toast.error(res.errorMessage || res?.errormessage[0]?.errcode);
            setError(res.errorMessage);
            setVisible(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  return (
    <>
      <ToastContainer />
      <Loader loader={loading} />
      <Modal isOpen={props.open}>
        <ModalHeader>
          <span className="mb-2 text-light">Upload Files</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => props.setOpen(false)}
          >
            X
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            {file === null && (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles, id);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable form-control p-0">
                    <div className="needsclick" {...getRootProps()}>
                      <input {...getInputProps()} accept="xlsx/*" />
                      <div className="mx-4 d-flex align-items-center">
                        <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                        <span className="text-muted fs-13   ">Upload File</span>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}

            {file !== null && (
              <Card>
                <div className="d-flex">
                  <span className="p-3">{file}.xlsx</span>
                  <i
                    className="ri-delete-bin-6-line fs-3  pointer red p-2"
                    onClick={() => setFiles(null)}
                  ></i>
                </div>
              </Card>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ width: "100px" }}
            onClick={() => {
              props.setOpen(false);
            }}
            color="success"
            outline
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" style={{ width: "100px" }}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Dialog
        header="Header"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <DataTable value={error} tableStyle={{ minWidth: "50rem" }}>
          <Column field="errcode" header="Error Code"></Column>
          <Column field="index" header="Index"></Column>
        </DataTable>
      </Dialog> */}
    </>
  );
};

export default BulkUpload;
